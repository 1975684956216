<template>
    <div>
        <div ref="gwn" class="customerDetail customerDetail-gwn">
            <div class="customerDetail-info">
                <van-cell-group>
                    <van-cell title="线索名称" :value="leads_detail.realname" />
                    <van-cell title="跟进状态" :value="task_detail.task_step_text" />
                    <van-cell title="联系电话">
                        <template slot="default">
                            <a class="customerDetail-gwn-cellphone"  @click='makeCall()' :href="'tel:'+leads_detail.cellphone">{{leads_detail.cellphone}}</a>
                        </template>
                    </van-cell>
                    <van-cell title="所属校区" :value="leads_detail.studio_name" />
                    <van-cell title="来源渠道" :value="leads_detail.channel_name" />
                    <van-cell title="课程顾问" :value="leads_detail.distribute_to" />
                    <van-cell title="回访时间" :value="leads_detail.next_time" />
                    <van-cell title="线索备注" :value="leads_detail.remark" v-if="leads_detail.remark" />
                </van-cell-group>
            </div>
            <div class="customerDetail-list">
                <van-steps direction="vertical" :active="-1">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="listFinishedText"
                        @load="onLoadLog"
                        >
                        <van-step v-for="item in list" :key="item.id">
                            <div class="customerDetail-list-user">
                                <div class="customerDetail-list-avatar">
                                    <avatar :username="item.employee_name" :src="item.employee_avatar" color="#fff" :size="24"></avatar>
                                    <span style="margin:0 5px;font-size:12px">{{item.employee_name}}</span>
                                </div>
                                <div style="font-size:12px">
                                    {{item.created_at}}
                                </div>
                            </div>
                            <div>{{item.log}}</div>
                            <div class="customerDetail-list-img">
                                <img v-for="(it,ind) in item.log_images" :key="ind" @click="toImagePreview(item.log_images)" :src="it.image" alt="">
                            </div>
                        </van-step>
                    </van-list>
                </van-steps>
            </div>
            <div
                class="float-ball"
                ref="ball"
                @touchstart.stop='handleTouchstart'
                @touchmove.stop='handleTouchmove'
                @touchend.stop='handleTouchend'
                @click.stop="showPopupGroup=true"
                :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
                >
                <van-icon color="#00cca2" name="more-o" />
            </div>
            <van-button @click='makeCall()' type="primary"  icon="phone-circle-o" :url="'tel:'+leads_detail.cellphone" block class="report-btn">
                {{leads_detail.cellphone}}
            </van-button>
            <van-share-sheet v-model="showPopupGroup" @select="onSelect" :options="shareOptions"></van-share-sheet>
            <van-popup v-model="showState" round position="bottom">
                <van-form @submit="onSubmitState">
                    <van-field name="radio" label="跟进状态">
                        <template #input>
                            <van-radio-group :value="task_step" direction="horizontal">
                                <van-radio @click="handleRadioChange(item)" style="margin-bottom:5px" v-for="item in dealStatus" :key='item.value' :name="item.value">{{item.label}}</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-button type="primary" size="large">发布</van-button>
                </van-form>
            </van-popup>
            <van-popup v-model="showLog" round position="bottom">
                <van-form @submit="onSubmitLog">
                    <van-field name="radio" label="跟进状态">
                        <template #input>
                            <van-radio-group :value="task_step" direction="horizontal">
                                <van-radio @click="handleRadioChange(item)" style="margin-bottom:5px" v-for="item in dealStatus" :key='item.value' :name="item.value">{{item.label}}</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>

                    <van-field
                        readonly
                        clickable
                        :value="next_time"
                        label="回访时间"
                        placeholder="点击选择"
                        @click="showNextTime = true"
                    />
<!-- 
                    <van-field
                    v-model="fieldValue"
                    is-link
                    readonly
                    label="地区"
                    placeholder="请选择所在地区"
                    @click="show = true"
                    />
                    <van-popup v-model="show" round position="bottom">
                    <van-cascader
                        v-model="cascaderValue"
                        title="请选择所在地区"
                        :options="options"
                        @close="show = false"
                        @finish="onFinish"
                    />
                    </van-popup> -->

                    <van-collapse v-model="activeNames" accordion>
                        <van-collapse-item title="快捷跟进" name="1">
                            <van-tag round v-for="(item,i) in discourse" :key="i" 
 style="margin-right:10px;">
                                <div style="max-width:100px" class="van-ellipsis" @click="discourseClick(item)">#{{item.discourse_content}}</div>
                            </van-tag>
                        </van-collapse-item>
                    </van-collapse>

                    <van-field v-model="follow_log"
                        rows="4"
                        autosize
                        label="跟进内容"
                        type="textarea"
                        maxlength="500"
                        placeholder="请输入跟进内容"
                        right-icon="https://i01.wedots.cn/2023/05/24/ce151beb0825d70084408999c3980a01.png"
                        @click-right-icon="handleRightClick"
                        show-word-limit
                    />
                    <van-button :disabled="is_follow_log" type="primary" size="large">发布</van-button>
                </van-form>
            </van-popup>
            <van-popup v-model="showNextTime" round position="bottom">
                <van-datetime-picker
                    :value='next_time?dayjs(next_time).$d:dayjs().$d'
                    type="datetime"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm='handleNextTime'
                    />
            </van-popup>
            <loadings :loading='loading' />
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import gwm from 'gwm'
    import 'dayjs/locale/zh-cn'
    import Avatar from 'vue-avatar'
    import Loadings from '@/components/loading'
    import { Tag, Collapse, CollapseItem ,Col, Row, Tab, Tabs, ShareSheet, Popup, RadioGroup, Radio, Cell, CellGroup, Step, Steps, Form, Field, Toast, Dialog, DatetimePicker, ImagePreview, Cascader } from 'vant'
    export default {
        name:'customerDetail',
        components: {
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Col.name]: Col,
            [Tag.name]: Tag,
            [Row.name]: Row,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [ShareSheet.name]: ShareSheet,
            [Popup.name]: Popup,
            [Cascader.name]: Cascader,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Step.name]: Step,
            [Steps.name]: Steps,
            [Form.name]: Form,
            [Field.name]: Field,
            loadings:Loadings,
            [Avatar.name]: Avatar,
            [DatetimePicker.name]: DatetimePicker,
        },
        data() {
            return {
                // show: false,
                // fieldValue: '',
                // cascaderValue: '',
                // fieldNames: {
                //     text: 'name',
                //     value: 'code',
                //     children: 'items',
                // },
                // options: [
                //     {
                //     name: '浙江省',
                //     code: '330000',
                //     items: [{ name: '杭州市', code: '330100' }],
                //     },
                //     {
                //     name: '江苏省',
                //     code: '320000',
                //     items: [{ name: '南京市', code: '320100' }],
                //     },
                // ],
                active: 0,
                task_id:'',
                task_detail:{},
                leads_detail:{},
                loading:false,
                moveTop:415,
                moveLeft:317,
                task_step:-1,
                task_step_text:'',
                modaldata:{},
                discourse: [],
                activeNames: 0,
                follow_log:'',
                next_time:'',
                isVoice:false,
                voice:{
                    localId: '',
                    serverId: ''
                },
                showState:false,
                showLog:false,
                showPopupGroup:false,
                shareOptions: [
                    [
                        { name: '手机微信号', icon: 'wechat', id:1 },
                        // { name: '状态变更', icon: require('../../assets/follow.png'), id:2 },
                        { name: '线索跟进', icon: require('../../assets/follow_txt.png'), id:3 },
                        { name: '客户转化', icon: require('../../assets/transform.png'), id:4 },
                    ],
                ],
                dealStatus: [
                    { label: '未跟进', value: 0 , color:'#1ab394'},
                    { label: '跟进中', value: 1 , color:'#23c6c8'},
                    { label: '已预约', value: 2 , color:'#10aeff'},
                    { label: '已到访', value: 3 , color:'#f8ac59'},
                    { label: '已成单', value: 4 , color:'#f76260'},
                    { label: '暂搁', value: 5 , color:'#ababab'},
                    { label: '无效', value: 10 , color:'#ababab'},
                    { label: '未接通', value: 6 , color:'#ababab'},
                    { label: '长期跟进', value: 15 , color:'#ababab'},
                ],
                list: [],
                finished: false,
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
                minDate: dayjs().add(1,'day').$d,
                maxDate: dayjs().add(30,'day').$d,
                is_follow_log:false,
                showNextTime:false,
            }
        },
        created () {
            dayjs.locale('zh-cn')
            this.task_id = this.$route.query.task_id
            this.getemployeeWatermark()
            this.getDetail()
            this.getDiscourse()
            this.getLog()
        },
        methods: {
            dayjs,
            makeCall(){
                this.$api.crm_leads_call({task_id:this.task_id})
            },
            getDiscourse(){
                this.$api.discourse_leads()
                .then(res=>{
                    this.discourse = res.data
                    // console.log(this.discourse)
                    // console.log(res);
                    // this.options=res.data.cascader_list;
                    // this.$refs.cascader.updateTabs()
                })
            },
            discourseClick(item){
                if(this.follow_log){
                   this.follow_log += '，'+item.discourse_content;
                }else{
                   this.follow_log = item.discourse_content;
                }
                this.$api.discourse_click({id:item.discourse_id}).then(res=>{})
            },
            handleRightClick(){
                var that = this;
                if(that.isVoice){
                    that.isVoice = false;
                    wx.stopRecord({
                        success: function (res) {
                            that.voice.localId = res.localId;
                            // wx.playVoice({
                            //     localId: that.voice.localId
                            // });
                            wx.translateVoice({
                                localId:that.voice.localId,
                                complete: function (res) {
                                    if (res.hasOwnProperty('translateResult')) {
                                        that.follow_log += res.translateResult
                                    } else {
                                        alert('语音转文字失败！');
                                    }
                                }
                            });
                        },
                        fail: function (res) {
                            alert(JSON.stringify(res));
                        }
                    });
                }else{
                    wx.startRecord({
                        success: function(res) {
                            var tempFilePath = res.tempFilePath 
                            that.isVoice = true;
                            // console.log(tempFilePath)
                            // alert(tempFilePath);
                        },
                        fail: function(res) {
                            //录音失败
                        }
                    })
                }
            },
            getDetail() {
                this.loading = true
                this.$api.crm_leads_detail({task_id:this.task_id})
                .then(res=>{
                    this.task_detail = res.data.task_detail
                    this.leads_detail = res.data.leads_detail
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
             onFinish({ selectedOptions }) {
                this.show = false;
                this.fieldValue = selectedOptions.map((option) => option.text).join('/');
            },
            getemployeeWatermark(){
                if(localStorage.getItem('employee_watermark')){
                    this.$nextTick(()=>{
                        gwm.creation({
                            container:this.$refs.gwn,
                            txt:localStorage.getItem('employee_watermark'),
                            mode: 'svg',
                            watch: false,
                            fontSize: 11,
                            height:80,
                            x: 20,
                            y: 30,
                            color: '#bbbfca',
                            font: 'sans-serif',
                            alpha: 0.2,
                            angle: -15
                        })
                    })
                }else{
                    this.$api.employee_index().then(res => {
                        localStorage.setItem('employee_watermark',res.data.employee.employee_watermark)
                        this.$nextTick(()=>{
                            gwm.creation({
                                container:this.$refs.gwn,
                                txt:localStorage.getItem('employee_watermark'),
                                mode: 'svg',
                                watch: false,
                                fontSize: 11,
                                height:80,
                                x: 20,
                                y: 30,
                                color: '#bbbfca',
                                font: 'sans-serif',
                                alpha: 0.2,
                                angle: -15
                            })
                        })
                    })
                }
            },
            getLog() {
                this.$api.crm_leads_follow_log({task_id:this.task_id,'per-page': this.pageSize, page: this.pageCurrent,})
                .then(res=>{
                    this.loading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.pageCurrent >= res._meta.currentPage
                    this.listFinishedText = this.list.length ? '没有更多了' : '无跟进记录~'
                    this.pageCurrent += 1
                })
            },
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20 
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleRadioChange(item){
                this.task_step = item.value
                this.task_step_text = item.label
            },
            onSelect(option){
                let that = this
                if(option.id === 1){
                    this.$copyText(this.leads_detail.cellphone).then(function (e) {
                        Toast.success('复制成功')
                    }, function (e) {
                        Toast.success('复制出错')
                    })
                }
                // if(option.id === 2){
                //     this.showState = true
                //     this.task_step = this.task_detail.task_step
                // }
                if(option.id === 3){
                    this.task_step = this.task_detail.task_step
                    this.next_time = this.leads_detail.next_time
                    this.showLog = true
                }
                if(option.id === 4){
                    Dialog.confirm({
                    title: '提示',
                    message: '确认将线索转为客户',
                    })
                    .then(() => {
                        that.toTransform()
                    })
                }
            },
            toTransform(){
                this.$api.crm_leads_transform({task_id:this.task_id})
                .then(res=>{
                    Toast.success('转移成功')
                    this.showShare = false
                    this.$router.go(-1)
                })
                .catch(err=>{
                    Toast.error('转移失败')
                })
            },
            onSubmitState(){
                this.$api.crm_leads_task_update({task_id:this.task_id,task_step:this.task_step})
                .then(res=>{
                    Toast.success('操作成功')
                    this.showState = false
                    this.task_detail.task_step = this.task_step
                    this.task_detail.task_step_text = this.task_step_text
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            onSubmitLog(){
                if(!this.next_time){
                    Toast.success('请选择回访时间～')
                    return false
                }
                if(!this.follow_log){
                    Toast.success('请选择填写跟进内容～')
                    return false
                }
                this.is_follow_log = true
                this.$api.crm_leads_follow({task_id:this.task_id,log:this.follow_log,task_step:this.task_step,next_time:this.next_time})
                .then(res=>{
                    Toast.success('操作成功')
                    this.follow_log = ''
                    this.showLog = false
                    this.is_follow_log = false
                    this.pageCurrent = 1
                    this.getDetail()
                    this.getLog()
                    this.modaldata.task_step = this.task_step
                    this.modaldata.task_step_text = this.task_step_text
                    if(!this.task_detail.task_step){
                        this.task_detail.task_step_text = '跟进中'
                        this.task_detail.task_step = 1
                    }
                })
                .catch(err=>{
                    Toast.error('操作失败')
                })
            },
            onLoadLog(){
                if(this.pageCurrent !=1){
                    this.getLog()
                }
            },
            handleNextTime(e){
                this.next_time = dayjs(e).format('YYYY-MM-DD HH:mm')
                this.showNextTime = false
            },
            toImagePreview(arr){
                let img = []
                arr.forEach(item=>{
                    img.push(item.image)
                })
                ImagePreview(img)
            },
        },
    }
</script>

<style lang="less">
    .report-btn{
        width: 100%;
        height: 40px;
        background-color: #00DE93;
        position: fixed;
        bottom: 0;
        text-align: center;
        line-height: 50px;
        color: #fff;
        z-index:1000;
    }
    .customerDetail{
        .van-cell-group{
            width: 95%;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;
        }
        &-info{
            .van-cell__value{
                flex: 3;
            }
            .van-cell__label{
                display: flex;
                align-items: center;
            }
        }
        &-list{
            width: 95%;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            // height: calc(100vh - 260px);
            // overflow-y: auto;
            border-radius: 8px;
            .van-steps--vertical{
                // padding: 0 0 0 145px;
                border-radius: 8px;
                overflow: hidden;
            }
            .van-list__loading{
                // margin-left: -150px;
            }
            .van-list__finished-text{
                // margin-left: -150px;
                margin-top: 5px;
            }
            &-time{
                // position: absolute;
                // left: -135px;
                // top: 10px;
            }
            &-img{
                img{
                    max-width: 46px;
                    max-height: 46px;
                    margin-right: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
            &-user{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
            }
            &-avatar{
                display: flex;
                align-items: center;
            }

        }

    }
    .customerDetail-gwn>:first-child{
        z-index: 100;
    }
    .customerDetail-gwn-cellphone{
        position: relative;
        z-index: 101;
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        z-index: 102;
        .van-icon{
            font-size: 0.8rem;
        }
    }
</style>